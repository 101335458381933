import { NEON } from './consts';
import { defineChain } from '@reown/appkit/networks'

export const neonEthersMainnet = defineChain({
  id: 245022934,
  caipNetworkId: 'eip155:245022934',
  chainNamespace: 'eip155',
  name: 'Neon EVM (MainNet)',
  nativeCurrency: {
    decimals: 18,
    name: 'Neon',
    symbol: NEON
  },
  blockExplorers: {
    default: {
      name: 'Neonscan',
      url: 'https://neonscan.org'
    }
  },
  rpcUrls: {
    default: {
      http: ['https://neon-mainnet.everstake.one']
    }
  },
  contracts: {
    multicall3: {
      address: '0xaC6FEaF379B01e3B0888597c53bb37e2B0b513ab',
      blockCreated: 205206112
    }
  }
})

export const neonEthersDevnet = defineChain({
  id: 245022926,
  caipNetworkId: 'eip155:245022926',
  chainNamespace: 'eip155',
  name: 'Neon EVM (DevNet)',
  nativeCurrency: {
    decimals: 18,
    name: 'Neon',
    symbol: NEON
  },
  blockExplorers: {
    default: {
      name: 'Neonscan',
      url: 'https://devnet.neonscan.org'
    }
  },
  rpcUrls: {
    default: {
      http: ['https://devnet.neonevm.org']
    }
  },
  contracts: {
    multicall3: {
      address: '0x5238c694a8db837fff8c4068859e765b978a7607',
      blockCreated: 205206112
    }
  }
})
