import { Injectable } from '@angular/core';
import { GasToken, NEON_TOKEN_MINT_DECIMALS } from '@neonevm/token-transfer-core';
import {
  BehaviorSubject,
  first,
  from,
  Observable,
  ReplaySubject,
  startWith,
  SubscriptionLike,
  switchMap,
  throwError
} from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  capitalizeFirstLetter,
  isObject,
  itemUnsubscribe,
  NEON,
  neonEthersDevnet,
  neonEthersMainnet,
  parseJson
} from '../../utils';
import { NotificationService } from '../../notifications';
import { LocalStorage } from '../../models';
import { ChainSwitchComponent } from '../components';
import { WalletConnectService } from './wallet-connect.service';
import { ProxyStatusService } from './proxy-status.service';
import { CaipNetwork } from '@reown/appkit-common';

@Injectable()
export class NeonChainService {
  loading = new BehaviorSubject<boolean>(false);
  chains: CaipNetwork[] = [neonEthersDevnet, neonEthersMainnet];
  private sub: SubscriptionLike[] = [];
  private _tokenList: GasToken[] = [];
  private _tokenList$: ReplaySubject<GasToken[]> = new ReplaySubject<GasToken[]>(0);

  get chain(): CaipNetwork | undefined {
    const i = this.chains.findIndex(i => i.id === this.p.chainId);
    if (i > -1) {
      return this.chains[i];
    }
    return undefined;
  }

  get chainName(): string {
    const i = this.chains.findIndex(i => i.id === this.p.chainId);
    if (i > -1) {
      return this.chains[i].name;
    }
    return ``;
  }

  get tokenList$(): Observable<GasToken[]> {
    return this._tokenList$.pipe(startWith(this.tokenList));
  }

  get tokenList(): GasToken[] {
    if (!this._tokenList.length) {
      const list = localStorage.getItem(LocalStorage.tokenList) ?? '';
      if (list?.length > 0) {
        try {
          this.tokenList = JSON.parse(list);
          this.p.currentGasToken(this.tokenList);
        } catch (e) {
          console.log(e);
        }
      }
    }
    return this._tokenList;
  }

  set tokenList(tokenList: GasToken[]) {
    this.tokenListEmit(tokenList);
  }

  get isSupportedChain(): boolean {
    return this.chains.some(i => i.id === this.p.chainId);
  }

  switchNetwork(chainId = this.p.chainId): Observable<any> {
    this.loading.next(true);
    return this.neon.provider$.pipe(switchMap(p => {
      return from(p?.send('wallet_switchEthereumChain', [{ chainId: `0x${chainId.toString(16)}` }]));
    }), finalize(this.loadingComplete));
  }

  addNetwork(chain: any, chainName = this.chainName): Observable<any> {
    this.loading.next(true);
    return this.neon.provider$.pipe(switchMap(p => {
      return from(p?.send('wallet_addEthereumChain', [{
        chainName,
        chainId: `0x${chain.id.toString(16)}`,
        rpcUrls: [chain.rpcUrl],
        blockExplorerUrls: [chain.explorerUrl],
        nativeCurrency: {
          name: chain.currency,
          symbol: NEON,
          decimals: NEON_TOKEN_MINT_DECIMALS
        }
      }]));
    }), finalize(this.loadingComplete));
  }

  chainSwitchModal(): void {
    const chain = this.chains.find(chain => chain.id === this.p.chainId);
    this.neon.modal.switchNetwork(chain as CaipNetwork);
  }

  chainSwitch(chainId = this.p.chainId, chainName = this.chainName): Observable<any> {
    const id = this.chains.findIndex(i => i.id === chainId);
    if (id > -1) {
      return this.switchNetwork(<number>this.chains[id].id).pipe(first(), tap(_ => {
          this.n.success({ title: `Success`, message: `Network was changed` });
        }),
        catchError(e => {
          const title = `Error`;
          let message = capitalizeFirstLetter(e.message.split(/[.()]/)[0]);
          if (e?.message && isObject(e.message)) {
            const pe = parseJson<{ message: string }>(e.message);
            message = pe.message ? pe.message : message;
          }
          this.n.error({ title, message });
          return throwError(() => ({ message }));
        }));
    }
    return throwError(() => new Error(`NeonPassError: chain with ${chainId} and ${chainName} not found`));
  }

  showSwitchNotification(data: [connected: boolean, chainId: number]): void {
    const [connected, chainId] = data;
    if (connected) {
      const c = Number(chainId);
      const id = this.n.notifications.findIndex(i => i.template === ChainSwitchComponent);
      if (id === -1 && (c !== this.neon.chainId || c !== this.p.chainId)) {
        this.n.template(ChainSwitchComponent, { closeByAction: true });
      } else if (id > -1 && c === this.p.chainId) {
        if (c !== this.neon.chainId$.value) {
          this.neon.chainId$.next(c);
        }
        this.n.close(id);
      }
    }
  }

  init(): void {
    this.sub.push(this.p.nativeTokenList().pipe(tap(this.p.currentGasToken), tap(this.tokenListEmit)).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.sub);
  }

  private tokenListEmit = (tokenList: GasToken[]): void => {
    this._tokenList = tokenList;
    this._tokenList$.next(this._tokenList);
    localStorage.setItem(LocalStorage.tokenList, JSON.stringify(this._tokenList));
  };

  private loadingComplete = (): void => {
    this.loading.next(false);
  };

  constructor(private neon: WalletConnectService, private p: ProxyStatusService, private n: NotificationService) {
  }
}
